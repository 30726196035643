import React, { useState, useEffect } from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";

// Pages
import Index from './components/pages/Index';
import Whitelist from './components/pages/Whitelist';
import LookAtMe from './components/pages/LookAtMe';
import NoMatch from './components/pages/NoMatch';

// Components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

const AppName = 'Hypercube Premints';
const AppUrl = 'https://premint.hypercube.art';
// const AppSalesLimit = 10000;

const LinkTw = 'https://twitter.com/luminanceone';
const LinkDs = 'https://discord.com/invite/NRchP2em7x';
const LinkOpensea = '#';
const LinkEtherscan = '#';

const providerOptions = {
  injected: {
    display: {
      name: "MetaMask",
      description: "Connect with the provider in your Browser"
    },
    package: null
  },

  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: process.env.REACT_APP_INFURA_API_KEY,
      network: "mainnet",
      qrcodeModalOptions: {
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar"
        ]
      }
    }
  },

  torus: {
    package: Torus, // required
    options: {
      // networkParams: {
        // host: "https://localhost:8545", // optional
        // chainId: 1, // optional
        // networkId: 1// optional
      // },
      // config: {
        // buildEnv: "development" // optional
      // }
    }
  }
};

const web3Modal = new Web3Modal({
  // network: "goerli",
  network: "mainnet",
  cacheProvider: true,
  providerOptions
});

let provider = null;
let web3 = null;

function App() {
  const [currentPage, setCurrentPage] = useState('');
  // For whitelist
  const [winnersSpots] = useState(1440);

  // For Web3
  const [salesLaunched] = useState(true);
  const [isProvider, setIsProvider] = useState(false);
  const [userAddress, setUserAddress] = useState('0');
  const [chainID, setChainID] = useState(0);

  useEffect(() => {
    getCachedProvider();
    getUserAddress();
  });

  const getCachedProvider = async (e) => {
    if(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")){
      web3Connect();
      //console.log("Reconnect");
    }
  }

  const getUserAddress = async (e) => {
    if(isProvider){
      const accounts = await web3.eth.getAccounts();
      if(accounts[0]){
        setUserAddress(accounts[0]);
        //console.log('new account');
        //setUserAddress(accounts[0].toLowerCase());
      } else {
        setUserAddress('0');
        web3Disconnect();
        //console.log('no account');
      }

      const chainId = await web3.eth.getChainId();
      if(chainId){
        setChainID(chainId);
      } else {
        setChainID(0);
      }
    } else {
      setUserAddress('0');
      setChainID(0);
      web3Disconnect();
    }
  }

  async function web3Connect() {
    if (!provider) {
      try {
        provider = await web3Modal.connect();
        web3 = new Web3(provider);
        // CollectionContract = new web3.eth.Contract(abiStorage, contractAddr);
        setIsProvider(true);
      } catch (error) {
        // console.log('User rejected the request');
        // console.log(error);
      }
    }
  }

  async function web3Disconnect() {
    web3Modal.clearCachedProvider();
    setIsProvider(false);
    setUserAddress('0');
    provider = null;
  }

  if(provider){
    provider.on("accountsChanged", (accounts) => {
      getUserAddress();
    });

    provider.on("chainChanged", (chainId) => {
      getUserAddress();
    });
  }

  return (
    <BrowserRouter>
      <>
        <Header LinkTw={LinkTw} LinkDs={LinkDs} currentPage={currentPage} AppName={AppName} salesLaunched={salesLaunched} />
        {(currentPage === 'whitelist2') &&
        <div className="image-after-header">
          <div className="title-1">CHRYSANTHEMUM</div>
          <div className="title-2">by Luminance x TRIPP</div>
        </div>
        }
        <main>
          <div id="content">
            <Routes>
                <Route path='/' exact strict element={<Index AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} salesLaunched={salesLaunched} />} />

                <Route path='/chrysanthemum' exact strict element={<Whitelist AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} web3Connect={web3Connect} isProvider={isProvider} userAddress={userAddress} provider={provider} salesLaunched={salesLaunched} winnersSpots={winnersSpots} />} />

                <Route path='/look-at-me' exact strict element={<LookAtMe AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} isProvider={isProvider} userAddress={userAddress} />} />

                <Route path="*" element={<NoMatch AppName={AppName} AppUrl={AppUrl} setCurrentPage={setCurrentPage} />} />
            </Routes>
          </div>
        </main>
        <Footer LinkTw={LinkTw} LinkDs={LinkDs} LinkOpensea={LinkOpensea} LinkEtherscan={LinkEtherscan} AppName={AppName}/>
      </>
    </BrowserRouter>
  );
}

export default App;
