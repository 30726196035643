import { HashLink as Link } from 'react-router-hash-link';
import logo from "../../assets/images/logo.png";

const Footer = ({LinkTw, LinkDs, LinkEtherscan, LinkOpensea, AppName}) => {
    return (
        <footer>
            <Link to="/#top" className="footer_logo" smooth>
                <img src={logo} alt={AppName} title={AppName} width="25px" height="25px" />
            </Link>
            <div className="footer_menu">
                {/*
                <Link to="/terms#top" title="Terms of use" smooth>Terms of use</Link>
                <Link to="/faq#top" title="FAQ" smooth>FAQ</Link>
                <a href={LinkEtherscan} title="Contract" target="_blank" rel="noreferrer nofollow noopener">Contract</a>
                <a href={LinkOpensea} title="OpenSea" target="_blank" rel="noreferrer nofollow noopener">OpenSea</a>
                */}
            </div>
            <div className="footer_social">
                <a href={LinkTw} title="Twitter" target="_blank" rel="noreferrer nofollow noopener" className="hs-tw"><svg viewBox="0 0 512 512" x="0px" y="0px" ><g><g><path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016 c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992 c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056 c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152 c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792 c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44 C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568 C480.224,136.96,497.728,118.496,512,97.248z"/></g></g></svg></a>
                <a href={LinkDs} title="Discord" target="_blank" rel="noreferrer nofollow noopener" className="hs-ds"><svg viewBox="0 0 512 512" height="512" width="512"><g><path d="m484.629 225.058c-20.379-70.018-43.361-106.196-43.732-106.845-1.31-1.599-33.786-40.463-112.059-69.209l-10.343 28.16c37.237 13.676 63.655 30.36 78.184 41.14-43.376-12.953-94.977-20.886-140.679-20.886s-97.303 7.933-140.679 20.886c14.528-10.779 40.946-27.464 78.183-41.14l-10.343-28.16c-78.271 28.746-110.747 67.61-112.057 69.209-.371.649-23.354 36.827-43.732 106.845-19.64 67.476-27.101 162.665-27.372 166.482 1.75 2.736 42 71.456 151.693 71.456l27.804-40.262c-31.15-8.271-60.853-21.609-88.043-39.708l16.623-24.973c43.865 29.197 95.016 44.631 147.923 44.631s104.058-15.434 147.923-44.631l16.623 24.973c-27.189 18.099-56.893 31.437-88.043 39.708l27.804 40.262c109.693 0 149.943-68.72 151.693-71.456-.271-3.817-7.732-99.006-27.371-166.482zm-297.987 78.482h-30v-50h30zm168.716 0h-30v-50h30z"/></g></svg></a>
            </div>
        </footer> 
    )
}

export default Footer