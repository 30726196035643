import PaperLogin from '../common/PaperLogin';
import './ConnectPage.css';

const ConnectPage = ({web3Connect, pageName, checkPaperLogin}) => {
    return (
        <>
            <h2 style={{marginBottom: "15px"}}>You need to connect your e-wallet</h2>
            <div onClick={() => web3Connect()} className="connect-button">Connect wallet</div>
            <h2 style={{marginTop: "20px", marginBottom: "15px"}}>Don't have a wallet?</h2>
            <div className="paper-button-container"><PaperLogin checkPaperLogin={checkPaperLogin} /></div>
            <p>You can easily create a wallet with <a href="https://paper.xyz" title="Paper" target="_blank" rel="noreferrer nofollow noopener">paper.xyz</a> via your email</p>
        </> 
    )
}

export default ConnectPage