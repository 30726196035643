import { useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import {Link} from 'react-router-dom';

const Index = ({AppName, AppUrl, setCurrentPage, salesLaunched}) => {

    useEffect(() => {
		setCurrentPage('index');
	}, [setCurrentPage]);

    return (
        <>
            <Helmet>
                <title>{AppName}</title>
                <meta name="description" content={AppName} />
                <meta name="keywords" content={AppName} />
                <meta property="og:title" content={AppName} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={AppName} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <div className="page-text">
                <h1 style={{textAlign: "center"}}>Hypercube Premints</h1>
                {salesLaunched && <div style={{textAlign: "center"}}><Link to="/chrysanthemum">Chrysanthemum</Link></div>}
                
            </div>
        </>
    )
}

export default Index