import { useState, useEffect } from 'react';
import { LoginWithPaper } from "@paperxyz/react-client-sdk";
import axios from 'axios';

const apiPath = '/api';

const client = axios.create({
    baseURL: apiPath,
    headers: { "Content-type": "application/json" } 
});

const PaperLogin = ({checkPaperLogin}) => {
    const onSuccessLogin = async (code) => {
        // console.log('Success Login');
        // console.log(code);

        client.post(`/paper/get-user-token`, { code: code }) 
        .then(res => {
            if(res.status === 200){
                console.log('Paper - OK');
                checkPaperLogin();
                // console.log(res.data.user_address);
                // console.log(res.data.user_email);      
            }
        }).catch(error => {
            console.log('Error');
            // console.log(error);
        });
    };
     
    return (
        <>
            <LoginWithPaper onSuccess={onSuccessLogin} /><br />
        </>
    )
}

export default PaperLogin