import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import queryString from 'query-string'; // For  twitter
import { Helmet } from "react-helmet-async";
import Cookies from 'js-cookie';

// Components
import ConnectPage from '../common/ConnectPage';

import './Whitelist.css';

import done from "../../assets/images/done-small-2x.jpg";
import doneBig from "../../assets/images/done-big-2x.jpg";
import doneGreen from "../../assets/images/done-small-green-2x.jpg";
import doneGrey from "../../assets/images/done-small-grey-2x.jpg";
import twitterSmall from "../../assets/images/twitter-small-2x.png";
import ethereumSmall from "../../assets/images/ethereum-2x.png";
// import emailSmall from "../../assets/images/email-2x.png";

const apiPath = '/api';
const client = axios.create({
  baseURL: apiPath,
  headers: { "Content-type": "application/json" } 
});

const Whitelist = ({AppName, AppUrl, setCurrentPage, web3Connect, isProvider, userAddress, provider, salesLaunched, winnersSpots}) => {
    const [isListed, setIsListed] = useState(false);

    // Email
    const [userEmail, setUserEmail] = useState('');
    const [searchParams, setSearchParams] = useSearchParams(); 
    
    // Twitter
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState();
    //const [name, setName] = useState();
    const [username, setUsername] = useState();
    const [isTwitterValid, setIsTwitterValid] = useState(false);

    const [isFollower, setIsFollower] = useState(false);
    const [isFollowerMsg, setIsFollowerMsg] = useState(false);

    const [addErrorMsg, setAddErrorMsg] = useState();

    // Paper
    const [isPaperLoggedIn, setIsPaperLoggedIn] = useState(false);
    const [isPaperUserActive, setIsPaperUserActive] = useState(false);
    const [paperEmail, setPaperEmail] = useState();
    const [paperAddress, setPaperAddress] = useState('0');
    
    useEffect(() => {
      setCurrentPage('whitelist2');
    }, [setCurrentPage]);
  
    useEffect(() => {
        if(!isProvider){
            checkPaperLogin();
        }
        
        checkListed();
        getEmailFromUrl();

        if(provider){
          provider.on("accountsChanged", (accounts) => {
            removeLocalEmail();
            logoutTwitter();
          });
        }
    });

    const checkPaperLogin = async() => {
        let paper_cookie_token = Cookies.get('paper_hypertoken');

        if(paper_cookie_token){
            // Надо сделать запрос и получить email и address
            client.get(`/paper/bytoken/${paper_cookie_token}`)
                .then(res => {
                    if(res.data.success){
                        // console.log('Address: ' + res.data.address);
                        // console.log('Email: ' + res.data.email);
                        setIsPaperLoggedIn(true);
                        setPaperAddress(res.data.address);
                        setPaperEmail(res.data.email);
                        if(res.data.active === 1){
                            setIsPaperUserActive(true);
                        } else {
                            setIsPaperUserActive(false);
                        }
                    } else {
                        setIsPaperLoggedIn(false);
                        setIsPaperUserActive(false);
                        setPaperAddress('0');
                        setPaperEmail();
                    }
                }).catch(error => {
                    setIsPaperLoggedIn(false);
                    setIsPaperUserActive(false);
                    setPaperAddress('0');
                    setPaperEmail();
                });
        } else {
            setIsPaperLoggedIn(false);
            setIsPaperUserActive(false);
            setPaperAddress('0');
            setPaperEmail();
        }

        checkListed();
    }

    const checkListed = () => {
        if(isPaperLoggedIn){
            if(paperAddress){
                if(paperAddress !== '0'){
                    let cleanAddress = paperAddress.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
                    cleanAddress = cleanAddress.replace(/[^a-z0-9]/gi, '');
                    cleanAddress = cleanAddress.trim();

                    client.get(`/whitelists/byaddress/${cleanAddress}`)
                        .then(res => {
                            if(res.data.success){
                                setIsListed(true);
                            } else {
                                setIsListed(false);
                            }
                        }).catch(error => {
                            setIsListed(false);
                        });
                } else {
                    setIsListed(false);
                }
            } else {
                setIsListed(false);
            } 
        } else {
            if(userAddress){
                if(userAddress !== '0'){
                    let cleanAddress = userAddress.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
                    cleanAddress = cleanAddress.replace(/[^a-z0-9]/gi, '');
                    cleanAddress = cleanAddress.trim();

                    client.get(`/whitelists/byaddress/${cleanAddress}`)
                        .then(res => {
                            if(res.data.success){
                                setIsListed(true);
                            } else {
                                setIsListed(false);
                            }
                        }).catch(error => {
                            setIsListed(false);
                            //console.log('Error');
                        });
                } else {
                    setIsListed(false);
                }
            } else {
                setIsListed(false);
            } 
        }
    }

    // Email
    const getEmailFromUrl = () => {
        if(!userEmail){
            let urlEmail = searchParams.get("email");

            if(urlEmail){
                let mail_format = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if(urlEmail.match(mail_format)){
                    localStorage.setItem('hypercube2_userEmail', urlEmail);
                    setUserEmail(urlEmail);
                } else {
                    localStorage.setItem('hypercube2_userEmail','-');
                    setUserEmail('-');
                }
            } else {
                let localEmail = localStorage.getItem('hypercube2_userEmail');
                if(localEmail){
                    setUserEmail(localEmail);
                } else {
                    setUserEmail('-');
                }
            }
        }
    }

    const removeLocalEmail = () => {
        localStorage.setItem('hypercube2_userEmail','');
        setUserEmail('');
    }

    useEffect(() => {
        (async() => {
            if(!isListed){
                const {oauth_token, oauth_verifier} = queryString.parse(window.location.search); 

                if (oauth_token && oauth_verifier) {
                    try {
                    //Oauth Step 3
                    await axios({
                        url: `${apiPath}/twitter/oauth/access_token`,  
                        method: 'POST',
                        data: {oauth_token, oauth_verifier}
                    });
                    } catch (error) {
                        // console.error(error); 
                    }
                
                try {
                //Authenticated Resource Access
                const {data} = await axios({
                    url: `${apiPath}/twitter/users/profile_banner`,
                    method: 'GET'
                });

                //console.log(data.data.id);
                //console.log(data.data.name);
                //console.log(data.data.username);

                setIsLoggedIn(true);
                setUserId(data.data.id);
                //setName(data.data.name);
                setUsername(data.data.username);

                if(data.data.id){
                    client.get(`/whitelists/byuserid/${data.data.id}`)
                    .then(res => {
                            if(res.data.success){
                                // twitter is NOT in DB, we can add
                                setIsTwitterValid(true);
                            } else {
                                // Twitter is already in DB
                                setIsTwitterValid(false);
                            }
                    }).catch(error => {
                            setIsListed(false);
                            //console.log('Error');
                    });
                }
                
                } catch (error) {
                    // console.error(error); 
                }
                
                }
            }
        })();
    }, []);

    useEffect(() => {
      checkFollow();
    }, [isLoggedIn]);

    // Twitter
    const login = () => {
        (async () => {
          try {
            //OAuth Step 1
            const response = await axios({
              url: `${apiPath}/twitter/oauth/request_token`, 
              method: 'POST'
            });
            
            const { oauth_token } = response.data;

            //Oauth Step 2
            //console.log(oauth_token)
            window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token}`;
          } catch (error) {
            // console.error(error); 
          } 
        })();
    }

    const logoutTwitter = () => {
        (async () => {
            try {
              await axios({
                url: `${apiPath}/twitter/logout`, 
                method: 'POST'
              });

              setIsLoggedIn(false);
              setUserId();
              //setName();
              setUsername();
              setIsTwitterValid(false);

              setIsFollower(false);
              setIsFollowerMsg(false);

              //console.log('Logout')
            } catch (error) {
              //console.error(error); 
            }
        })();
    }
    
    const checkFollow = () => {
        setIsFollowerMsg(false);
        if(isLoggedIn){
            if(userId){
                client.get(`/twitter/users/follow/${userId}`)
                .then(res => {
                    if(res.data.success){
                        setIsFollower(true);
                        setIsFollowerMsg(false);
                    } else {
                        setIsFollower(false);
                        setIsFollowerMsg(true);
                    }
                }).catch(error => {
                    setIsFollower(false);
                    setIsFollowerMsg(true);
                });
            }
        }
    }

    const addToWhitelist = async(e) => {
        setAddErrorMsg();
        if(isPaperLoggedIn){
            if(isPaperUserActive){
                if(paperAddress){
                    if(paperAddress !== '0'){
                        if(userId){
                            if(username){
                                    client.post(`/whitelists`, {uaddress: paperAddress, uid: userId, uname: username, uemail: paperEmail, usource: 'paper'},{headers: {'APIMKEY': process.env.REACT_APP_API_M_KEY}})
                                    .then(res => {
                                        // console.log(res);
                                        if(res.status === 200){
                                            console.log('User added');
                                            removeLocalEmail();
                                            logoutTwitter();
                                            window.location.replace(process.env.REACT_APP_ADD_CALLBACK_URL);
                                        }
                                    }).catch(error => {
                                        // console.log('Error: ' + error.response.data.error);
                                        setAddErrorMsg(error.response.data.error);
                                    }); 
                            }
                        } 
                    }
                }
            }
        } else {
            if(userAddress){
                if(userAddress !== '0'){
                    if(userId){
                        if(username){
                                client.post(`/whitelists`, {uaddress: userAddress, uid: userId, uname: username, uemail: userEmail, usource: 'web3modal'},{headers: {'APIMKEY': process.env.REACT_APP_API_M_KEY}})
                                .then(res => {
                                    // console.log(res);
                                    if(res.status === 200){
                                        console.log('User added');
                                        removeLocalEmail();
                                        logoutTwitter();
                                        window.location.replace(process.env.REACT_APP_ADD_CALLBACK_URL);
                                    }
                                }).catch(error => {
                                    // console.log('Error: ' + error.response.data.error);
                                    setAddErrorMsg(error.response.data.error);
                                }); 
                        }
                    } 
                }
            }
        }
    }

    const logoutPaper = () => {
        (async () => {
            try {
              await axios({
                url: `${apiPath}/paper/logout`, 
                method: 'POST'
              });

              setIsPaperLoggedIn(false);
              setIsPaperUserActive(false);
              setPaperAddress('0');
              setPaperEmail();

              logoutTwitter();
              
            } catch (error) {
              //console.error(error); 
            }
        })();
    }

    return (
        <>
            <Helmet>
                <title>{'Chrysanthemum Whitelist - ' + AppName}</title>
                <meta name="description" content={'Chrysanthemum Whitelist - ' + AppName} />
                <meta name="keywords" content='Chrysanthemum Whitelist' />
                <meta property="og:title" content={'Chrysanthemum Whitelist - ' + AppName} />
                <meta property="og:url" content={AppUrl+'/chrysanthemum'} />
                <meta property="og:description" content={'Chrysanthemum Whitelist - ' + AppName} />
                <meta property="og:image" content={AppUrl + '/share.jpg'} />
                <link rel="canonical" href={AppUrl+'/chrysanthemum'} />
            </Helmet>

            {(!salesLaunched)?(<>
              <div className="page-text">
                <h1 style={{textAlign: "center"}}>Whitelist not open yet!</h1>
              </div>
            </>):(<>
              <div className="page-text">
                  <h1 className="wallet-title">Chrysanthemum Allowlist Raffle</h1>
                  
                  <div className="whitelist-text-container">
                      <div className="whitelist-text-container-params">
                          <div className="whitelist-param-1">Mint Price<span>0.25Ξ</span></div>
                          <div className="whitelist-param-2">Number of Winners<span>{winnersSpots} Spots</span></div>
                          <div className="whitelist-param-3">Official Link<a href="https://www.hypercube.art/chrysanthemum" title="Twitter" target="_blank" rel="noreferrer nofollow noopener">hypercube.art/chrysanthemum</a></div>
                      </div>

                      <h2>Chrysanthemum by Luminance x TRIPP</h2>
                      
                      <p><i>We are on a mission to catalyze greater harmony on Earth through shared intention, attention and breath. This becomes possible</i></p>

                      <p>Chrysanthemum is the culmination of several years of passionate research and creative practice. These mesmerizing, kinetic mandalas blossomed from a desire to create beautiful and functional art as a tool for empowerment, inspiration and immune system health. Then, in a moment of inspiration, the teams realized they could combine the fractal imagery of this generative art with another algorithm they'd developed to translate heartbeats into usable audiovisual cues.</p>

                      <p>As a result, the teams have created a soundtrack with music stems composed by Grammy Award Nominated artists Justin Boreta (Glitch Mob) and Matt Davis of Superposition, in addition to David Starfire, head of Music&Sound at TRIPP, developing a unique sound experience that harnesses the power of biofeedback to develop personalized binaural audio that is tuned to your heartbeat, and a dynamic musical score that changes with every session.</p>

                      <p>After the drop, holders of the Chrysanthemum NFT will be entitled to receive a physical heart rate monitor as well as access to a custom web app powered by the Chrysanthemum NFT, offering a fully immersive audio-visual experience.</p>
  
                      <p><b>Connect your wallet for a chance to win one of {winnersSpots} allowlist spots for this mint at a price of 0.25 ETH.</b></p>
  
                      <p>Follow us on Twitter for other opportunities to win a spot:</p>
                      <p>
                          <a href="https://twitter.com/luminanceone" title="Luminance" target="_blank" rel="noreferrer nofollow noopener">Luminance</a><br />
                          <a href="https://twitter.com/trippvr" title="TRIPP" target="_blank" rel="noreferrer nofollow noopener">TRIPP</a><br />
                          <a href="https://twitter.com/hypercube_art" title="HyperCube" target="_blank" rel="noreferrer nofollow noopener">HyperCube</a><br />
                      </p>            
                  </div>
                  
                  <div className="whitelist-status-container">
                  {(isPaperLoggedIn)?(<>
                    {(isPaperUserActive)?(<>
                        {(isListed)?(
                            <>
                                <div className="whitelist-status-container-white">
                                    <div className="whitelist-status-container-white-title">
                                        <img src={done} alt="Registered" title="Registered" width="24px" height="24px" /><span>Registered</span>
                                    </div>
                                    <div className="whitelist-status-container-white-subtitle">{paperAddress}</div>
                                    <div className="whitelist-status-container-paper-logout" onClick={logoutPaper}>Log Out</div>
                                </div>
                                <div className="whitelist-status-container-green">
                                    <img src={doneBig} alt="Registered" title="Registered" width="48px" height="48px" />
                                    Thanks, you're on the list!<br />Keep an eye on our Twitter for announcements.
                                    <a href="http://twitter.com/share?text=Just%20registered%20for%20the%20Chrysanthemum%20NFT%20drop%20@LuminanceOne%20&url=https://premint.hypercube.art/chrysanthemum" title="Tweet" target="_blank" rel="noreferrer nofollow noopener" className="whitelist-status-container-tweet-button">
                                        <img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Tweet</span>
                                    </a>        
                                </div>
                                <br /><br />
                            </>
                        ):(
                            <>
                                <div className="whitelist-status-container-white">
                                    <div className="whitelist-status-container-white-title"><span>Register</span></div>
                                    <div className="whitelist-status-container-white-subtitle">Follow the steps below to add yourself to this list</div>
                                    <div className="whitelist-status-container-white-requirements"> 
                                        <b>Requirements:</b>
                                        <ul>
                                            <li><img src={ethereumSmall} alt="Ethereum" width="16px" height="16px" style={{marginTop: "2px"}}/>Connect Paper wallet</li>
                                            <li><img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Connect to twitter to verify username</span></li>
                                            <li><img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Follow <a href="https://twitter.com/ChrysanthemApp" title="Twitter" target="_blank" rel="noreferrer nofollow noopener">@ChrysanthemApp</a> on Twitter</span></li>
                                        </ul>
                                    </div>
                                            
                                    <div className="whitelist-status-container-white-connected"> 
                                        <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>Wallet Connected</span></div>
                                        <div className="whitelist-status-container-white-connected-subtitle">{paperAddress}</div>
                                        <div className="whitelist-status-container-paper-logout" onClick={logoutPaper} style={{marginTop: "5px"}}>Log Out</div>
                                    </div>
                                    
                                    {(!isLoggedIn)?(<>
                                        <div className="whitelist-status-container-white-disconnected">
                                            <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Connect Twitter</span></div>
                                            <div className="wscwb-twitter" onClick={login}>Connect Twitter</div>
                                        </div>

                                        <div className="whitelist-status-container-white-disconnected block-not-ready">
                                            <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Check Followers</span></div>
                                        </div>
                                    </>):(<>
                                        {(!isTwitterValid)?(<>
                                            <div className="whitelist-status-container-white-disconnected">
                                                <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Connect Twitter</span></div>
                                                <div className="whitelist-status-container-white-email-error">Twitter is already in the whitelist!</div>
                                                <div className="wscwb-error" onClick={logoutTwitter}>Log Out</div>
                                            </div>

                                            <div className="whitelist-status-container-white-disconnected block-not-ready">
                                                <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Check Followers</span></div>
                                            </div>
                                        </>):(<>
                                            <div className="whitelist-status-container-white-connected"> 
                                                <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>Twitter connected</span></div>
                                                <div className="whitelist-status-container-white-connected-subtitle">{'@' + username}<span className="whitelist-status-container-white-remove-email" onClick={logoutTwitter}>Change</span></div>
                                            </div>
                                            
                                            {(!isFollower)?(<>
                                                <div className="whitelist-status-container-white-disconnected">
                                                    <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>ChrysanthemApp's Follower</span></div>
                                                                
                                                    {(isFollowerMsg)?(<><div className="whitelist-status-container-white-email-error">You need to follow @ChrysanthemApp on Twitter!</div></>):("")}
                                                                
                                                    <div className="wscwb-twitter" onClick={checkFollow}>Check followers</div>
                                                </div>
                                            </>):(<>
                                                <div className="whitelist-status-container-white-connected"> 
                                                    <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>ChrysanthemApp's Follower</span></div>
                                                    <div className="whitelist-status-container-white-connected-subtitle">Follower</div>
                                                </div>

                                                <div className="whitelist-status-container-big-button" onClick={addToWhitelist}>Register</div>
                                                        
                                                {addErrorMsg && <div className="whitelist-status-container-big-arror">{'Error: ' + addErrorMsg}</div>}
                                            </>)}
                                        </>)}
                                    </>)}              
                                </div>
                            </>
                        )}
                    </>):(<>
                        <div className="whitelist-status-container-white">
                            <h3>User disabled</h3>
                            <div className="whitelist-status-container-paper-logout" onClick={logoutPaper}>Log Out</div>
                        </div>
                    </>)}
                  </>):(<>      
                      {(!isProvider)?(
                          <div className="whitelist-status-container-connect">
                              <ConnectPage web3Connect={web3Connect} pageName={'whitelist'} checkPaperLogin={checkPaperLogin}/>
                          </div>
                      ):(
                          <>    
                              {(isListed)?(
                              <>
                                  <div className="whitelist-status-container-white">
                                      <div className="whitelist-status-container-white-title">
                                          <img src={done} alt="Registered" title="Registered" width="24px" height="24px" /><span>Registered</span>
                                      </div>
                                      <div className="whitelist-status-container-white-subtitle">{userAddress}</div>
                                  </div>
                                  <div className="whitelist-status-container-green">
                                      <img src={doneBig} alt="Registered" title="Registered" width="48px" height="48px" />
                                      Thanks, you're on the list!<br />Keep an eye on our Twitter for announcements.
                                      <a href="http://twitter.com/share?text=Just%20registered%20for%20the%20Chrysanthemum%20NFT%20drop%20@LuminanceOne%20&url=https://premint.hypercube.art/chrysanthemum" title="Tweet" target="_blank" rel="noreferrer nofollow noopener" className="whitelist-status-container-tweet-button">
                                        <img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Tweet</span>
                                      </a>        
                                  </div>
                                  <br /><br />
                              </>
                              ):(
                                  <>
                                      <div className="whitelist-status-container-white">
                                          <div className="whitelist-status-container-white-title"><span>Register</span></div>
                                          <div className="whitelist-status-container-white-subtitle">Follow the steps below to add yourself to this list</div>
                                          <div className="whitelist-status-container-white-requirements"> 
                                              <b>Requirements:</b>
                                              <ul>
                                                  <li><img src={ethereumSmall} alt="Ethereum" width="16px" height="16px" style={{marginTop: "2px"}}/>Connect wallet</li>
                                                  <li><img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Connect to twitter to verify username</span></li>
                                                  <li><img src={twitterSmall} alt="twitter" width="18px" height="14px" /><span>Follow <a href="https://twitter.com/ChrysanthemApp" title="Twitter" target="_blank" rel="noreferrer nofollow noopener">@ChrysanthemApp</a> on Twitter</span></li>
                                              </ul>
                                          </div>
                                          
                                          <div className="whitelist-status-container-white-connected"> 
                                              <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>Wallet Connected</span></div>
                                              <div className="whitelist-status-container-white-connected-subtitle">{userAddress}</div>
                                          </div>

                                              {(!isLoggedIn)?(<>
                                                  <div className="whitelist-status-container-white-disconnected">
                                                        <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Connect Twitter</span></div>
                                                        <div className="wscwb-twitter" onClick={login}>Connect Twitter</div>
                                                  </div>

                                                  <div className="whitelist-status-container-white-disconnected block-not-ready">
                                                      <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Check Followers</span></div>
                                                  </div>
                                              </>):(<>
                                                  {(!isTwitterValid)?(<>
                                                    <div className="whitelist-status-container-white-disconnected">
                                                        <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Connect Twitter</span></div>
                                                        <div className="whitelist-status-container-white-email-error">Twitter is already in the whitelist!</div>
                                                        <div className="wscwb-error" onClick={logoutTwitter}>Log Out</div>
                                                    </div>

                                                    <div className="whitelist-status-container-white-disconnected block-not-ready">
                                                        <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>Check Followers</span></div>
                                                    </div>
                                                  </>):(<>
                                                    <div className="whitelist-status-container-white-connected"> 
                                                        <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>Twitter connected</span></div>
                                                        <div className="whitelist-status-container-white-connected-subtitle">{'@' + username}<span className="whitelist-status-container-white-remove-email" onClick={logoutTwitter}>Change</span></div>
                                                    </div>
                                                    
                                                    {(!isFollower)?(<>
                                                        <div className="whitelist-status-container-white-disconnected">
                                                            <div className="whitelist-status-container-white-disconnected-title"><img src={doneGrey} alt="undone icon" width="18px" height="18px" /><span>ChrysanthemApp's Follower</span></div>
                                                            
                                                            {(isFollowerMsg)?(<>
                                                                <div className="whitelist-status-container-white-email-error">You need to follow @ChrysanthemApp on Twitter!</div>
                                                            </>):("")}
                                                            
                                                            <div className="wscwb-twitter" onClick={checkFollow}>Check followers</div>
                                                        </div>
                                                    </>):(<>
                                                      <div className="whitelist-status-container-white-connected"> 
                                                        <div className="whitelist-status-container-white-connected-title"><img src={doneGreen} alt="done icon" width="18px" height="18px" /><span>ChrysanthemApp's Follower</span></div>
                                                        <div className="whitelist-status-container-white-connected-subtitle">Follower</div>
                                                      </div>

                                                      <div className="whitelist-status-container-big-button" onClick={addToWhitelist}>Register</div>
                                                      
                                                      {addErrorMsg && <div className="whitelist-status-container-big-arror">{'Error: ' + addErrorMsg}</div>}
                                                    </>)}
                                                  </>)}
                                              </>)}
                                          
                                      </div>
                                  </>
                              )}
                          </>
                      )}
                  </>)}
                  </div>
              </div>
            </>)}
        </>
    )
}

export default Whitelist