import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import axios from 'axios';

const apiPath = '/api';
const client = axios.create({
  baseURL: apiPath,
  headers: { "Content-type": "application/json" } 
});

const LookAtMe = ({AppName, AppUrl, setCurrentPage, isProvider, userAddress}) => {
    const [usersList, setUsersList] = useState();
    const [activeViewer, setActiveViewer] = useState(false);

    useEffect(() => {
		setCurrentPage('look-at-me');
	}, [setCurrentPage]);

    useEffect(() => {
        checkViewer();

        if(!usersList){
            getList();
        }
	});

    const getList = async() => {
        client.get(`/whitelists/lookatme/2`)
        .then(res => {
            if(res.data){
                setUsersList(res.data);
                //console.log(res.data);
            }
        }).catch(error => {
            console.log('Error');
        });
    }

    const checkViewer = async() => {
        if(userAddress){
            if(userAddress !== '0'){
                client.get(`/viewers/byaddress/${userAddress}`)
                .then(res => {
                    if(res.data.success){
                        setActiveViewer(true);
                    } else {
                        setActiveViewer(false);
                    }
                }).catch(error => {
                    setActiveViewer(false);
                });
            } else {
                setActiveViewer(false);
            }
        } else {
            setActiveViewer(false);
        }
    }

    let userItems = '';
    if(usersList){
        if(usersList.length !== 0){
            userItems = usersList.map((user) =>
                <div>
                    {user.address} - <a href={"https://twitter.com/"+user.username} target="_blank">{'@'+user.username}</a>{(user.email != "-")?(<>{" - " + user.email}</>):("")}{(user.source == "paper")?(<>{" from paper"}</>):("")}
                </div>
            );
        }       
    }

    return (
        <>
            <Helmet>
                <title>{'Premint list - ' + AppName}</title>
                <meta name="description" content={'Premint list - ' + AppName} />
                <meta name="keywords" content={'Premint list - ' + AppName} />
                <meta property="og:title" content={'Premint list - ' + AppName} />
                <meta property="og:url" content={AppUrl} />
                <meta property="og:description" content={'Premint list - ' + AppName} />
                <link rel="canonical" href={AppUrl} />
            </Helmet>

            <div className="page-text">
                <h1 style={{textAlign: "center"}}>Hypercube Premint Lists</h1>

                {(activeViewer)?(<>
                    <h2>Collectoion 2</h2>
                    {(usersList)?(<>{userItems}</>):("Empty list")}
                </>):("Not available")}
            </div>
        </>
    )
}

export default LookAtMe